import React, { useState } from 'react';

import "../../styles/components/organisms/Navbar.scss";

import { AiOutlineLinkedin } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import { CgMenu } from "react-icons/cg";

import MemojiToine from "../../assets/images/memoji_toine.png";
import LogoBlue from "../../assets/images/logos/TW-black.png";

const Navbar = () => {

  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <>
      <div className='navbar'>
        <div className='navbar-content'>
          <div
            className='navbar-icon'
            onClick={() => { setShowSidebar(!showSidebar); }}
          >
            <CgMenu />
          </div>
          <a href='/'><h1 className='navbar-title'><img src={LogoBlue} alt="Logo" /></h1></a>
          <div className='navbar-section'>
            <a href='/aboutme'><p className='navbar-text'>About me</p></a>
            <a href='/contact'><p className='navbar-link'>Contact</p></a>
            <a className='navbar-socials' href='https://www.linkedin.com/in/toine-van-wonderen-23747219b/' target="_blank" rel="noreferrer">
              <AiOutlineLinkedin />
            </a>
          </div>
        </div>
      </div>

      <div className={`sidebar ${showSidebar && "sidebar--active"}`}>
        <div className='sidebar-header'>
          <div
            className='sidebar-header-close'
            onClick={() => { setShowSidebar(!showSidebar); }}
          >
            <IoCloseOutline />
          </div>
          <div className='sidebar-header-logo'>
            <img src={LogoBlue} alt="Logo" />
          </div>
        </div>
        <ul className='sidebar-list'>
          <li><a href='/'>Home</a></li>
          <li><a href='/aboutme'>About me</a></li>
          <li><a href='/contact'>Contact</a></li>
        </ul>
        <div className='sidebar-footer'>
          <img
            src={MemojiToine}
            alt="Toine Memoji"
            className='sidebar-footer-memoji'
          />
          <div>
            <a href='/'><h2>Toine van Wonderen</h2></a>
            <p>Create miracles</p>
            <div className='sidebar-footer-socials'>
              <a
                href='https://www.linkedin.com/in/toine-van-wonderen-23747219b/'
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineLinkedin />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar;
