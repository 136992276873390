import React, { useState, useRef } from 'react'
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ReCAPTCHA from "react-google-recaptcha";

import "../styles/pages/ContactPage.scss";

import {
    AiOutlineLinkedin,
    AiOutlineLoading3Quarters
} from "react-icons/ai";

import MemojiToine from "../assets/images/memoji_toine.png";

import Button from "../components/atoms/Button";

import { send } from '../services/MailService';

const contactTemplate = {
    name: "",
    email: "",
    message: "",
}

const ContactPage = () => {
    const [contactForm, setContactForm] = useState(contactTemplate);
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState()
    const [successMessage, setSuccessMessage] = useState()

    const reCaptchaRef = useRef();

    const setContactValue = (e) => {
        const { name } = e.target;
        let { value } = e.target;

        setContactForm({
            ...contactForm,
            [name]: value,
        });
    }

    const contactSchema = yup.object().shape({
        name: yup.string()
          .required("Enter your name"),
        email: yup.string()
          .email("Enter a valid email address")
          .required("Enter an email address"),
        message: yup.string()
          .required("Enter a message"),
    })

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(contactSchema),
    })

    const sendMessage = async (data) => {
        setLoading(true)

        // Excecute Recaptcha
        const token = await reCaptchaRef.current.executeAsync();
        Object.assign(data, {token})
        // Send mail
        send(data).then(data => {
            if (data.status !== 200) {
                setErrorMessage(<>Oops, something went wrong. You can send an email to <a href="mailto: info@toinevanwonderen.nl">info@toinevanwonderen.nl</a></>)
            } else {
                setSuccessMessage("Je bericht is verzonden!")
            }
            setLoading(false)
        })

        reCaptchaRef.current.reset();
        reset();
        setContactForm(contactTemplate);
    }

    return (
        <div className='contact'>
            <div className='contact-form'>
                <h1 className='contact-title'>
                    Let's get in touch
                </h1>
                <form
                    method='post'
                    onSubmit={handleSubmit(sendMessage)}
                >
                    <ReCAPTCHA
                        sitekey={"6LdjzMskAAAAAKKDWakc9n9DfnxJ1cyuoZYLJhM3"}
                        size="invisible"
                        ref={reCaptchaRef}
                    />
                    <div className='contact-form-container'>
                        <div className='contact-form-labels'>
                            <label>Name:</label>
                            <p className='contact-form-labels--error'>{errors.name?.message}</p>
                        </div>
                        <input
                            type="text"
                            name="name"
                            className='contact-form-input'
                            {...register("name")}
                            onChange={(e) => {setContactValue({target: {name: e.target.name, value: e.target.value}})}}
                        />
                    </div>
                    <div className='contact-form-container'>
                        <div className='contact-form-labels'>
                            <label>Email:</label>
                            <p className='contact-form-labels--error'>{errors.email?.message}</p>
                        </div>
                        <input
                            type="text"
                            name="email"
                            className='contact-form-input'
                            {...register("email")}
                            onChange={(e) => {setContactValue({target: {name: e.target.name, value: e.target.value}})}}
                        />
                    </div>
                    <div className='contact-form-container'>
                        <div className='contact-form-labels'>
                            <label>Message:</label>
                            <p className='contact-form-labels--error'>{errors.message?.message}</p>
                        </div>
                        <textarea
                            name='message'
                            className='contact-form-text-area'
                            {...register("message")}
                            onChange={(e) => {setContactValue({target: {name: e.target.name, value: e.target.value}})}}
                        ></textarea>
                    </div>
                    <div className='contact-form-container'>
                        <div className='contact-form-buttons'>
                            <Button
                                color="darkBlue"
                                text={(!loading) && "Send message"}
                                icon={(loading) && <div className='contact-form-buttons-loading'><AiOutlineLoading3Quarters /></div>}
                                onClick={() => {setErrorMessage(<></>); setSuccessMessage("")}}
                            />
                            <p>{(loading) && "Sending... It can take a while"}</p>
                            <p className='contact-form-buttons-error'>{errorMessage}</p>
                            <p className='contact-form-buttons-success'>{successMessage}</p>
                        </div>
                    </div>
                </form>
            </div>
            <div className='contact-border'>
                <div className='contact-border-line'></div>
            </div>
            <div className='contact-info'>
                <h1 className='contact-title'>Contact details</h1>
                <div className='contact-info-container'>
                    <img className='contact-info-image' src={MemojiToine} alt="Memoji Toine" />
                    <div className='contact-info-data'>
                        <h2 className='contact-info-title'>Toine van Wonderen:</h2>
                        <p className='contact-info-text'>Email: <a href='mailto: info@toinevanwonderen.nl'>info@toinevanwonderen.nl</a></p>
                        <div className='contact-info-socials'>
                            <a
                                href='https://www.linkedin.com/in/toine-van-wonderen-23747219b/'
                                target="_blank"
                                rel="noreferrer"
                            >
                                <AiOutlineLinkedin />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactPage
