import React from 'react'

import { AiOutlineLinkedin } from "react-icons/ai";

import "../../styles/components/organisms/Footer.scss";

const Footer = () => {
  return (
    <div className='footer'>
        <div className='footer-content'>
          <div className='footer-container footer-socials'>
            <h2>Toine van Wonderen</h2>
            <p>Create miracles</p>
            <div className='footer-socials-icons'>
              <a
                href='https://www.linkedin.com/in/toine-van-wonderen-23747219b/'
                target="_blank"
                rel="noreferrer"
              >
                <AiOutlineLinkedin />
              </a>
            </div>
          </div>
          <div className='footer-container footer-menu'>
            <h2>Menu</h2>
            <ul className='footer-menu-list'>
              <div>
              <li className='footer-menu-list-item'>
                  <a href='/'>
                    <p>Home</p>
                  </a>
                </li>
                <li className='footer-menu-list-item'>
                  <a href='/aboutme'>
                    <p>About me</p>
                  </a>
                </li>
              </div>
              <div>
                <li className='footer-menu-list-item'>
                  <a href='/contact'>
                    <p>Contact</p>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
        <div className='footer-copyright'>
          <p>© 2024 Toine van Wonderen. All Rights Reserved.</p>
        </div>
    </div>
  )
}

export default Footer
