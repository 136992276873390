import {
  FaJava,
  FaReact,
  FaPython,
  FaNodeJs,
  FaSass,
  FaCss3,
} from "react-icons/fa";
import { AiFillHtml5 } from "react-icons/ai";
import { DiCss3, DiPhp, DiCodeigniter } from "react-icons/di";
import { IoLogoJavascript } from "react-icons/io";
import { SiSpringboot, SiCoursera, SiTailwindcss } from "react-icons/si";
import { GrMysql } from "react-icons/gr";

import UvALogo from "../../assets/images/school/UvA_logo.png";
import HvALogo from "../../assets/images/school/HvA_logo.png";
import MegosuLogo from "../../assets/images/companies/Megosu_logo.png";
import AgerasLogo from "../../assets/images/companies/Ageras_logo.svg";

import AgerasMacBlue from "../../assets/images/ageras/Ageras_mac_blue.png";
import AgerasPhone from "../../assets/images/ageras/Ageras_phone.png";
import AgerasHoogte from "../../assets/images/ageras/ageras-hoogte.png";
import AgerasSecurity from "../../assets/images/ageras/ageras-security.png";
import AgerasVacatures from "../../assets/images/ageras/ageras-vacatures.png";
import MegosuHome from "../../assets/images/megosu/megosu_home.png";
import MegosuPhone from "../../assets/images/megosu/megosu_phone.png";
import MegosuHome2 from "../../assets/images/megosu/megosu_home_2.png";
import MegosuProduct from "../../assets/images/megosu/megosu_product.png";
import MegosuProfile from "../../assets/images/megosu/megosu_profile.png";
import MegosuSuccess from "../../assets/images/megosu/megosu_success.png";
import MegosuAbout from "../../assets/images/megosu/megosu_about.png";
import ampSS from "../../assets/images/amp/screenshot.png";
import ampHome from "../../assets/images/amp/home.png";
import ampPhone from "../../assets/images/amp/phone.png";
import ampLicense from "../../assets/images/amp/license.png";
import ampMusic from "../../assets/images/amp/songs.png";
import ampProjects from "../../assets/images/amp/projects.png";
import TC4AtheletesBanner from "../../assets/images/TC4Atheletes/tc4a_banner.png";
import TC4AtheletesHome from "../../assets/images/TC4Atheletes/tc4a_home.png";
import TC4AtheletesDashboard from "../../assets/images/TC4Atheletes/tc4a_dashboard.png";
import TC4AtheletesPhone from "../../assets/images/TC4Atheletes/tc4a_phone.png";

import MegosuSS from "../../assets/images/megosu/Megosu-ss.png";
import AgerasSS from "../../assets/images/ageras/Ageras-ss.png";

import Guido from "../../assets/images/Guido.jpeg";

export const programming_languages = [
  {
    name: "C",
    icon: <SiCoursera />,
  },
  {
    name: "Python",
    icon: <FaPython />,
  },
  {
    name: "Java",
    icon: <FaJava />,
  },
  {
    name: "Javascript",
    icon: <IoLogoJavascript />,
  },
  {
    name: "React",
    icon: <FaReact />,
  },
  {
    name: "NodeJS",
    icon: <FaNodeJs />,
  },
  {
    name: "PHP",
    icon: <DiPhp />,
  },
  {
    name: "CodeIgniter",
    icon: <DiCodeigniter />,
  },
  {
    name: "Springboot",
    icon: <SiSpringboot />,
  },
  {
    name: "MySQL",
    icon: <GrMysql />,
  },
  {
    name: "HTML5",
    icon: <AiFillHtml5 />,
  },
  {
    name: "CSS3",
    icon: <DiCss3 />,
  },
  {
    name: "Sass",
    icon: <FaSass />,
  },
];
export const schools = [
  {
    logo: UvALogo,
    link: "https://www.uva.nl/",
  },
  {
    logo: HvALogo,
    link: "https://www.hva.nl/",
  },
];
export const companies = [
  {
    logo: MegosuLogo,
    link: "https://www.megosu.com/",
  },
  {
    logo: AgerasLogo,
    link: "https://ageras-webapp.vercel.app/",
  },
];

export const projects = [
  {
    title: "Megosu",
    year: "2021 - 2022",
    type: "Web development",
    description:
      "In 2021, my internship at Megosu began. At that time, Megosu was a startup actively working on launching a platform for product designers. Alongside a team of other talented developers, I had the opportunity to undergo significant personal and professional development during this internship. I learned entirely new programming techniques and gained valuable insights into effective teamwork.",
    link: "https://www.megosu.com",
    tools: [
      {
        name: "React",
        icon: <FaReact />,
      },
      {
        name: "Springboot",
        icon: <SiSpringboot />,
      },
      {
        name: "Sass",
        icon: <FaSass />,
      },
    ],
    photos: [
      MegosuSS,
      MegosuHome,
      MegosuPhone,
      MegosuHome2,
      MegosuProduct,
      MegosuProfile,
      MegosuSuccess,
      MegosuAbout,
    ],
  },
  {
    title: "Afternoon Music Publishing",
    year: 2023,
    type: "Web development",
    description:
      "Afternoon Music Publishing is a website where the user can listen an buy high quality music. Besides listening to music the user can store and share their songs with other users. It was a lot of fun to build with multiple challanges to maintain quality and performance.",
    link: "https://afternoonmusicpublishing.com/",
    tools: [
      {
        name: "CodeIgniter",
        icon: <DiCodeigniter />,
      },
      {
        name: "PHP",
        icon: <DiPhp />,
      },
      {
        name: "CSS",
        icon: <FaCss3 />,
      },
      {
        name: "JavaScript",
        icon: <IoLogoJavascript />,
      },
    ],
    photos: [ampSS, ampHome, ampPhone, ampLicense, ampMusic, ampProjects],
  },
  {
    title: "TC4Athletes",
    year: 2024,
    type: "Software development",
    description:
      "Analysis platform that provides clear insights into athletes' sports performances through easy-to-understand graphs. Efficient data processing ensures optimal performance and fast load times for athletes who want to analyze their data.",
    link: "https://www.tc4athletes.com/",
    tools: [
      {
        name: "React",
        icon: <FaReact />,
      },
      {
        name: "NodeJS",
        icon: <FaNodeJs />,
      },
      {
        name: "Tailwind",
        icon: <SiTailwindcss />,
      },
    ],
    photos: [
      TC4AtheletesBanner,
      TC4AtheletesHome,
      TC4AtheletesPhone,
      TC4AtheletesHome,
      TC4AtheletesDashboard,
    ],
  },
  {
    title: "Ageras",
    year: 2022,
    type: "Web development",
    description:
      "The website for Ageras is meant for users to find information about Ageras and so that users can apply for jobs. The jobs can be found in a job board where the user can filter jobs to find the job that excactly fits with the user.",
    link: "https://www.ageras.nl",
    tools: [
      {
        name: "React",
        icon: <FaReact />,
      },
      {
        name: "NodeJS",
        icon: <FaNodeJs />,
      },
      {
        name: "Sass",
        icon: <FaSass />,
      },
    ],
    photos: [
      AgerasSS,
      AgerasMacBlue,
      AgerasPhone,
      AgerasVacatures,
      AgerasHoogte,
      AgerasSecurity,
    ],
  },
];

export const recensies = [
  {
    // quote: "\"Toine is super flexibel! Naast dat hij zijn werk gestructureerde uitvoert, is hij communicatief. Hij heeft groot deel van ons platform ontwikkeld. Dit was een complexe taak omdat de site veel data bevat en dynamisch is. Het was fijn dat Toine deze complexe taken in kindertaal met mij kon bespreken, zodat ik begreep welke keuzes ik had. Wanneer ik nogmaals opzoek ben naar een developer kom ik zeker bij je terug!\"",
    quote:
      '"Toine is super flexible! In addition to carrying out his work in a structured manner, he is communicative. He developed a large part of our platform. This was a complex task because the site contains a lot of data and is dynamic. It was nice that Toine could discuss these complex tasks with me in child\'s language, so that I understood what choices I had. When I am looking for a developer again, I will definitely come back to you!"',
    image: Guido,
    name: "Guido Lok",
    function: "Founder Megosu",
  },
];

// export const FAQ = [
//     {
//         question: "Welke service lever ik precies?",
//         anwser: <>Ik <b>bouw de website</b> en <b>zet de website live</b>, zodat alles zonder zorgen up and running staat. Optioneel maak ik ook een design en doe ik het onderhoud voor de website. Ik maak <b>geen</b> foto's voor de website.</>,
//     },
//     {
//         question: "Hoelang duurt een project?",
//         anwser: <>De projectduur hangt af van de eisen van de website. Dit kan vanaf <b>2 weken</b> bij een eenvoudige website oplopen tot <b>2 maanden</b> bij een complexe website.</>,
//     },
//     {
//         question: "Hoe verloopt het proces van een website maken?",
//         anwser: <>1. Eerst maken we een afspraak om te kijken naar alle wensen en eventueel design. 2. Nadat ik alle content heb ontvangen kan ik meteen aan de slag met het maken van de website 3. Als laatste kan ik het onderhoud doen voor de website.</>,
//     },
//     {
//         question: "Hoeveel kost een website?",
//         anwser: <>De prijs hangt af van de eisen van de website. Een simpele website waar bezoekers contact kunnen opnemen kan al vanaf <b>250,-</b>. Maar dit kan oplopen bij een grotere of complexere websites.</>,
//     },
// ]
