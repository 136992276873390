import React from 'react'

import "../../styles/components/atoms/Button.scss";

const Button = ({ text, icon, color, onClick, outline, customStyle }) => {
  return (
    <>
        {color === "lightBlue" && (
            <button
                className={`button ${outline ? "button-light-blue--outline" : "button-light-blue"}`}
                onClick={(e) => {onClick && onClick(e)}}
            >
                {icon && icon}
                <p>{text && text}</p>
            </button>
        )}
        {color === "darkBlue" && (
            <button
                className={`button ${outline ? "button-dark-blue--outline" : "button-dark-blue"}`}
                onClick={(e) => {onClick && onClick(e)}}
                style={customStyle}
            >
                {icon && icon}
                <p>{text && text}</p>
            </button>
        )}
        {color === "green" && (
            <button
            className={`button ${outline ? "button-green--outline" : "button-green"}`}
                onClick={(e) => {onClick && onClick(e)}}
                style={customStyle}
            >
                {icon && icon}
                <p>{text && text}</p>
            </button>
        )}
        {color === "darkGrey" && (
            <button
                className={`button ${outline ? "button-dark-grey--outline" : "button-dark-grey"}`}
                onClick={(e) => {onClick && onClick(e)}}
                style={customStyle}
            >
                {icon && icon}
            <p>{text && text}</p>
            </button>
        )}
        {color === "black" && (
            <button
                className={`button ${outline ? "button-black--outline" : "button-black"}`}
                onClick={(e) => {onClick && onClick(e)}}
                style={customStyle}
            >
                {icon && icon}
            <p>{text && text}</p>
            </button>
        )}
    </>
  )
}

export default Button
