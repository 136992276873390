import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import ReactGA from "react-ga4"

import './App.scss';

import NotFound from "./pages/NotFound";
import Home from "./pages/HomePage";
import OverMij from "./pages/AboutMePage";
import Contact from "./pages/ContactPage";

import Navbar from "./components/organisms/Navbar";
import Footer from "./components/organisms/Footer";

ReactGA.initialize("G-2DLEYX2MEC")

const Main = () => {

  return (
    <>
      <div className="App-content">
        <Navbar />
        <Routes>
          <Route path='*' element={<NotFound />}/>
          <Route path="/" element={<Home />} />
          <Route path="/aboutme" element={<OverMij />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>

      <Footer />
    </>
  )
}

const App = () => {
  return (
    <div className="App">
      <Router>
        <Main />
      </Router>
    </div>
  );
}

export default App;
