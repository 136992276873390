import React from 'react';
import { useNavigate } from "react-router-dom";

import "../../styles/components/molecules/ContactLink.scss";

import Button from "../../components/atoms/Button";

const ContactLink = () => {
  let navigate = useNavigate();
  return (
    <div className='contact-link'>
        <div className='contact-link-contact'>
            <p className='contact-link-text'>Questions?</p>
            <h1>Let's get in touch</h1>
            <div className='contact-link-button'>
                <Button
                    color="darkBlue"
                    text="Contact"
                    onClick={() => {
                      navigate("/contact");
                    }}
                />
            </div>
        </div>
    </div>
  )
}

export default ContactLink
