export function send(content) {
  const sendMailUrl = process.env.REACT_APP_API_BASE_URL + "/contact";
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(content)
  };
  return fetch(sendMailUrl, options);
}
