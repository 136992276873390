import React, { useEffect, useState, useRef } from "react";

import { BsPlus } from "react-icons/bs";
import { IoCloseOutline } from "react-icons/io5";
import { FiExternalLink, FiDownload } from "react-icons/fi";

import ContactLink from "../components/molecules/ContactLink";
import Button from "../components/atoms/Button";

import { projects, recensies } from "../assets/data/DataList";

import "../styles/pages/HomePage.scss";

import Portret from "../assets/images/Portret.jpg";
import { useNavigate } from "react-router-dom";

const projectTemplate = {
  title: "",
  year: 0,
  type: "",
  description: "",
  tools: [],
  photos: [],
};

const HomePage = () => {
  const navigate = useNavigate();
  const scrollToPage = useRef(null);
  const [showProject, setShowProject] = useState(true);
  const [showingProject, setShowingProject] = useState(projectTemplate);
  // const [questionActive, setQuestionActive] = useState(false);
  const [isDraggingCarousel, setIsDraggingCarousel] = useState(false);
  const [isDraggingBar, setIsDraggingBar] = useState(false);

  useEffect(() => {
    window.addEventListener("mouseup", () => {
      setIsDraggingCarousel(false);
      setIsDraggingBar(false);
    });
    // eslint-disable-next-line
  }, []);

  // Fade in/out single project
  const toggleShowProject = (index) => {
    setShowProject(!showProject);
    if (showProject) {
      setShowingProject(projects[index]);
      document.body.classList.add("noscroll");
    } else {
      setTimeout(() => {
        // Wait for transition to be done
        document.querySelector(".home-project").scrollTop = 0;
        document.querySelector(".home-project-photos-carousel").scrollLeft = 0;
        setShowingProject(projects[index]);
      }, 200);
      document.body.classList.remove("noscroll");
    }
  };
  // Project images drag
  const handleProjectDrag = (e) => {
    if (isDraggingCarousel || isDraggingBar) {
      const carousel = document.querySelector(".home-project-photos-carousel");
      const progressBar = document.querySelector(
        ".home-project-photos-bar--progress"
      );
      const bar = document.querySelector(".home-project-photos-bar");

      const maxLeft = bar.clientWidth - progressBar.clientWidth;
      const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;
      const xMove = isDraggingBar ? e.movementX : e.movementX * -1;
      const left = progressBar.offsetLeft + xMove;
      const scrollLeft = (left / maxLeft) * maxScrollLeft;
      if (left >= 0 && left <= maxLeft) {
        carousel.scrollLeft = scrollLeft;
        progressBar.style.left = left + "px";
      }
    }
  };
  // Progress bar at project images drag
  const setProjectProgressBar = (e) => {
    if (!isDraggingCarousel && !isDraggingBar) {
      const carousel = document.querySelector(".home-project-photos-carousel");
      const progressBar = document.querySelector(
        ".home-project-photos-bar--progress"
      );
      const bar = document.querySelector(".home-project-photos-bar");

      const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;
      const maxLeft = bar.clientWidth - progressBar.clientWidth;
      const scrollLeft = carousel.scrollLeft;
      const leftProgressBar = (scrollLeft / maxScrollLeft) * maxLeft;
      progressBar.style.left = leftProgressBar + "px";
    }
  };

  // // turn FAQ cards
  // const toggleFaq = (i) => {
  //   const questions = document.querySelectorAll(".home-faq-list-item");
  //   questions.forEach((q, index) => {
  //     if (index === i) {
  //       q.classList.toggle("home-faq-list-item--active");
  //       setQuestionActive(q.classList.contains("home-faq-list-item--active"));
  //     } else {
  //       q.classList.remove("home-faq-list-item--active");
  //     }
  //   })
  // }
  // // Hide FAQ card when clicking somewhere on page
  // document.addEventListener("click", function(e) {
  //   const questions = document.querySelectorAll(".home-faq-list-item");
  //   if (!questionActive) return;
  //   for (const q of questions){
  //     if (e.target.closest(".home-faq-list-item")) {
  //       break;
  //     }
  //     q.classList.remove("home-faq-list-item--active");
  //   }
  // })

  return (
    <div className="home">
      {/* Banner */}
      <div className="home-container--banner home-banner">
        <div className="home-banner-image">
          <div></div>
          <img src={Portret} alt="Portret" />
        </div>

        <div className="home-banner-info">
          <h1>University student</h1>
          <h1>
            that <span className="home-underline-blue">loves </span>
            <span className="home-underline-blue">coding</span>,
          </h1>
          <h1>learning and</h1>
          <h1>creating new things</h1>

          <div className="home-banner-info-container">
            <p className="home-text--regular">Toine van Wonderen</p>
            <p className="home-text--small">Software Engineer</p>
            {/* <div className='home-banner-socials'>
              <a href='https://www.linkedin.com/in/toine-van-wonderen-23747219b/' target="_blank" rel="noreferrer">
                <AiOutlineLinkedin />
              </a>
            </div> */}
          </div>

          {/* <a href='/aboutme'><p className='home-banner-about-link text-dark-blue'>Get to know more about me!</p></a> */}
          <div
            className="home-banner-arrow"
            onClick={() => {
              navigate("/aboutme");
            }}
          >
            <BsPlus />
            About me
          </div>
        </div>
      </div>

      {/* <div className='home-grey-background' ref={scrollToPage}>
        <div className='home-container home-statestieken'>
          <div className='home-statestieken-item'>
            <h1 className='text-blue'>4 years</h1>
            <p>Experience</p>
          </div>
          <div className='home-statestieken-item'>
            <h1 className='text-blue'>3+</h1>
            <p>Projects with companies</p>
          </div>
          <div className='home-statestieken-item'>
            <h1 className='text-blue'>100%</h1>
            <p>Passion</p>
          </div>
        </div>
      </div> */}

      {/* Portfolio */}
      <div className="home-portfolio">
        <div className="bg-black">
          <h1 className="text-grey home-portfolio-title">Projects</h1>
          <h2 className="text-grey home-portfolio-subtitle home-title">
            A selection of projects I have worked on
          </h2>
          <ul className="home-container home-portfolio-list">
            {projects.map((project, index) => (
              <li
                className="home-portfolio-list-item text-white"
                key={index}
                onClick={() => {
                  toggleShowProject(index);
                }}
              >
                <div className="home-portfolio-list-item-background">
                  <img
                    src={project.photos[0]}
                    alt="item"
                    className="home-portfolio-list-item-image"
                  />
                </div>
                <h2 className="home-portfolio-list-item-title">
                  {project.title}
                </h2>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Recensies */}
      <div className="home-container home-recensies">
        <h1>
          Read what <span className="home-underline-blue">people </span>
          <span className="home-underline-blue">think</span>
        </h1>
        <ul className="home-recensies-list">
          {recensies.map((r) => (
            <li className="home-recensies-list-item">
              <p className="home-text">{r.quote}</p>
              <div className="home-recensies-list-item-persoon">
                <img
                  src={r.image}
                  alt="item"
                  className="home-recensies-list-item-image"
                />
                <div>
                  <h2>{r.name}</h2>
                  <p>{r.function}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* FAQ */}
      {/* <div className='home-container home-faq'>
        <h1>Fun facts about me</h1>
        <ul className='home-faq-list'>
          {
            FAQ.map((q, index) => (
              <li
                className='home-faq-list-item'
                onClick={() => {toggleFaq(index)}}
                key={index}
              >
                <div className='home-faq-list-item-card'>
                  <div className='home-faq-list-item-card-front'>
                    <h2>{q.question}</h2>
                    <div className='home-faq-list-item-card-front-icon'>
                      <AiOutlinePlus />
                    </div>
                  </div>
                  <div className='home-faq-list-item-card-back'>
                    <div className='home-faq-list-item-card-back-anwser'>{q.anwser}</div>
                    <div className='home-faq-list-item-card-back-icon'>
                      <IoCloseOutline />
                    </div>
                  </div>
                </div>
              </li>
            ))
          }
        </ul>
      </div> */}

      <div className="home-container">
        <ContactLink />
      </div>

      {/* Single Project */}
      <div
        className={`home-project ${!showProject && "home-project--active"}`}
        onMouseDown={() => {
          toggleShowProject(0);
        }}
      >
        <div
          className={`home-project-container ${
            !showProject && "home-project-container--active"
          }`}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className="home-project-close"
            onMouseDown={() => {
              toggleShowProject(0);
            }}
          >
            <IoCloseOutline />
          </div>

          {showingProject.title !== "Pac Man" && (
            <>
              <img
                src={showingProject.photos[1]}
                alt="item"
                className="home-project-image"
              />
              <div className="home-project-border"></div>
            </>
          )}
          <p className="home-project-type">{showingProject.type}</p>
          <div className="home-project-title-block">
            <h1 className="home-project-title">{showingProject.title}</h1>
            {showingProject.title === "Pac Man" ? (
              <a href={showingProject.link} download="pacman.zip">
                <FiDownload />*
              </a>
            ) : (
              <a href={showingProject.link} target="_blank" rel="noreferrer">
                <FiExternalLink />
              </a>
            )}
          </div>
          <span>{showingProject.year}</span>
          <img
            src={showingProject.photos[2]}
            alt="item"
            className="home-project-image--spacing"
          />

          <div className="home-project-text-container">
            <p className="home-project-text-description">
              {showingProject.description}
              {showingProject.title === "Pac Man" && (
                <>
                  <br />
                  <br />* Download of the Pac-Man game from this website is
                  provided as-is, without warranties. We are not liable for any
                  damages or viruses resulting from the download.
                </>
              )}
            </p>

            <div className="home-project-tools">
              <h2 className="home-project-tools-title">Tools:</h2>
              <ul className="home-project-tools-list">
                {showingProject.tools.map((tool, index) => (
                  <li className="home-project-tools-list-item" key={index}>
                    {tool.icon}
                    <p className="text-white">{tool.name}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="home-project-border"></div>
          <div
            className="home-project-photos"
            onMouseMove={(e) => {
              handleProjectDrag(e);
            }}
            onMouseDown={() => {
              setIsDraggingCarousel(true);
            }}
          >
            <div
              className="home-project-photos-carousel"
              onScroll={(e) => {
                setProjectProgressBar(e);
              }}
            >
              {showingProject.photos.map(
                (photo, index) =>
                  index > 2 && (
                    <img
                      src={photo}
                      alt="Project foto"
                      className="home-project-photos-carousel-item"
                      key={index}
                      draggable="false"
                    />
                  )
              )}
            </div>
            <div className="home-project-photos-bar">
              <div
                className="home-project-photos-bar--progress"
                onMouseMove={(e) => {
                  handleProjectDrag(e);
                }}
                onMouseDown={() => {
                  setIsDraggingBar(true);
                }}
              ></div>
            </div>
          </div>
          <div class="home-project-button">
            <Button
              text={
                showingProject.title === "Pac Man"
                  ? "Download Pac Man*"
                  : "Visit " + showingProject.title
              }
              color="darkBlue"
              onClick={() => {
                if (showingProject.title === "Pac Man") {
                  const link = document.createElement("a");
                  link.href = showingProject.link;
                  link.download = "pacman.zip";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                } else {
                  window.open(`${showingProject.link}`);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
