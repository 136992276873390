import React, { useState, useEffect } from 'react'

import "../styles/pages/AboutMePage.scss";

import ContactLink from "../components/molecules/ContactLink";

import {
  programming_languages,
  schools,
} from '../assets/data/DataList';

import Portret from "../assets/images/Portret.jpg";
import PortretFontein from "../assets/images/portret_fontein.jpg";

const AboutMePage = () => {

  const [isDraggingCarousel, setIsDraggingCarousel] = useState(false);
  const [isDraggingBar, setIsDraggingBar] = useState(false);

  useEffect(() => {
    window.addEventListener("mouseup", () => {
      setIsDraggingCarousel(false);
      setIsDraggingBar(false);
    })
  });

  const handleLanguageDrag = (e) => {
    if (isDraggingCarousel || isDraggingBar) {
      const carousel = document.querySelector("#carousel_languages");
      const progressBar = document.querySelector(".about-carousel-bar--progress");
      const bar = document.querySelector(".about-carousel-bar");

      const maxLeft = bar.clientWidth - progressBar.clientWidth;
      const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;
      const xMove = (isDraggingBar) ? e.movementX : e.movementX * -1;
      const left = progressBar.offsetLeft + xMove;
      const scrollLeft = (left / maxLeft) * maxScrollLeft;
      if (left >= 0 && left <= maxLeft) {
        carousel.scrollLeft = scrollLeft;
        progressBar.style.left = left + "px";
      }
    }
  }

  const setProgressBar = (e) => {
    if (!isDraggingCarousel && !isDraggingBar) {
      const carousel = document.querySelector("#carousel_languages");
      const progressBar = document.querySelector(".about-carousel-bar--progress");
      const bar = document.querySelector(".about-carousel-bar");

      const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;
      const maxLeft = bar.clientWidth - progressBar.clientWidth;
      const scrollLeft = carousel.scrollLeft;
      const leftProgressBar = (scrollLeft / maxScrollLeft) * maxLeft;
      progressBar.style.left = leftProgressBar + "px";
    }
  }

  return (
    <div className='about'>
      <div className='about-grid'>
        {/* Image */}
        <div className='about-grid-item'>
            <img
                src={Portret}
                alt="Portret"
                className='about-image'
            />
        </div>

        {/* languages */}
        <div
          className='about-grid-item--extra-large-width'
          onMouseMove={(e) => { handleLanguageDrag(e); }}
          onMouseDown={() => { setIsDraggingCarousel(true); }}
        >
          <div className='about-grid-item--large-width-content about-languages'>
            <h1 className='text-white'>Programming languages</h1>
            <ul
              className='about-carousel'
              id='carousel_languages'
              onScroll={(e) => { setProgressBar(e) }}
            >
              {
                programming_languages.map((language, index) => (
                  <li className='about-carousel-item' key={index}>
                    {language.icon}
                    <p>{language.name}</p>
                  </li>
                ))
              }
            </ul>

            <div
              className='about-carousel-bar'
              onMouseDown={() => { setIsDraggingBar(true); }}
              onMouseMove={(e) => { handleLanguageDrag(e); }}
            >
              <div className='about-carousel-bar--progress'></div>
            </div>
          </div>
        </div>

        {/* School */}
        <div className='about-grid-item--large-width'>
          <div className='about-grid-item--large-width-content'>
            <h2>School:</h2>
            <div className='about-logos'>
              {
                schools.map((school, index) => (
                  <a href={school.link} key={index} target='_blank' rel="noreferrer">
                    <img src={school.logo} alt="School" />
                  </a>
                ))
              }
            </div>
          </div>
        </div>
      </div>

      <div className='about-container about-story'>
        <div className='about-story-text'>
          <h1>My story</h1>
          <p>
            As a child I was always involved with technology, from making my own game to my first website. That hobby has grown into a passion for web technology and software and I have been developing that for years.
          </p>
          <p>
            I combined my first year at higher professional education with an internship and obtained my propaedeutic diploma cum laude. Then I started at the University of Amsterdam and began my own company where I have already built several websites for clients.
          </p>
          <p>
            I am flexible and have strong communication skills, which allow me to work well in a team and explain complex tasks simply. In addition, I am motivated to learn more by taking on new challenges and learning from others.
          </p>
        </div>
        <img src={PortretFontein} alt="" />
      </div>

      <div className='about-container'>
        <ContactLink />
      </div>
    </div>
  )
}

export default AboutMePage
