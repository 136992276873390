import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    let navigate = useNavigate();
    useEffect(() => {
        navigate("/");
    })
  return (
    <h1>Page NotFound</h1>
  )
}

export default NotFound
